.App {
  text-align: center;
}

@keyframes move {
  0%   { transform: scale(1)   translate(10px, -25vh); }
  38%  { transform: scale(0.6, 1) translate(65vw, -20vh) rotate(240deg); }
  45%  { transform: scale(0.8, 1) translate(15vw, -30vh) rotate(120deg); }
  78%  { transform: scale(1.8, 1) translate(56vw, -30vh) rotate(-20deg); }
  80%  { transform: scale(1.7) translate(50vw, -30vh) rotate(-30deg); }
  100% { transform: scale(1)   translate(10px, -25vh); }
}

@keyframes rotateShake {
  0%   { transform: scale(1) rotate(0deg); }
  25%  { transform: scale(1.1) rotate(-15deg); }
  50%  { transform: scale(1.2) rotate(0deg); }
  75%  { transform: scale(1.1) rotate(15deg); }
  100% { transform: scale(1) rotate(0deg); }
}

@keyframes moveStrokes {
  from {
    stroke-dashoffset: 0;/*var(--total-length);*/
  }
  to {
    stroke-dashoffset: 0;
  }
}

.content-page {
  margin-top: 64px;
}

.page-title {
  margin-top: 5rem;
  margin-bottom: 3rem;
}

.grecaptcha-badge {
  visibility: hidden;
}